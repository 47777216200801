.group {
  grid-area: group;
  border-bottom: 1px solid #e9ebec;
}

.year {
  grid-area: year;
  border-bottom: 1px solid #e9ebec;
}

.target {
  grid-area: target;
  border-bottom: 1px solid #e9ebec;
}

.note {
  grid-area: note;
  border-bottom: 1px solid #e9ebec;
}

.action {
  grid-area: action;
  border-bottom: 1px solid #e9ebec;
}

.baseline {
  grid-area: baseline;
}

.baselineContainer {
  display: grid;
  grid-template-areas:
    'baseline';
  grid-template-columns: 252px;
}

.baselineContainer>div {
  padding-right: 0.8rem;
}

.gridContainer {
  display: grid;
  grid-template-areas:
    'group note year target action';
  grid-template-columns: 252px 210px 114px 96px 100px;

}

@media only screen and (min-width: 1120px) {
  .gridContainer {
    grid-template-columns: 25% auto 15% 15% 100px;
  }

  .baselineContainer {
    grid-template-columns: 25%;
  }
}

.gridContainer>div {
  padding-right: 0.8rem;
}

.gridContainer>div>label {
  background-color: #1818cc;
  text-align: center;
}

.gridBody {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  margin-bottom: 1rem;

}