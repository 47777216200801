.button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  min-width: 24px;
}

.downloadIcon {
  transition: transform 0.3s ease, fill 0.3s ease;
  fill: #013333;
  text-align: right;
}

.downloadIcon:hover svg path {
  fill: #09564B;
}

.downloadIconDisabled:hover svg path {
  fill: currentColor;
}