.trucount-popper-date-picker-class {
    /* To win specificity over react-joyride */
    z-index: 100000 !important;
}

.react-joyride__tooltip {
    border-radius: 0.75rem !important;
}

.btn:hover {
    background-color: #09564B !important;
    filter: contrast(1.25) !important;
}

.card {
    --vz-card-border-radius: 0.75rem;
    --vz-card-inner-border-radius: 0.75rem;
}

.alert {
    --vz-alert-border-radius: 0.75rem;
}

.subscribe {
    align-items: center;
    background: rgba(255, 255, 255, 0);
    backdrop-filter: blur(10px);
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 100;
}

@keyframes trucount-shake {
    0% {
        transform: translateY(0);
    }

    25% {
        transform: translateY(-0.3rem);
    }

    50% {
        transform: translateY(0);
    }

    75% {
        transform: translateY(0.3rem);
    }

    100% {
        transform: translateY(0);
    }
}

.line {
    fill: none;
    stroke-width: 3px;
}

.overlay {
    fill: none;
    pointer-events: all;
}

.hover-line {
    stroke: #b6b6b6;
    stroke-width: 1;
    stroke-dasharray: 3;
    fill: #b1b9c4;
    stroke-linecap: butt;
    filter: none;
}

.simplebar-scrollbar::before {
    background-color: AliceBlue !important;
    width: 4px;
    opacity: 0.7;
}

/* START OF CUSTOM SIMPLEBAR CSS */

/* Issue: https://github.com/Grsmto/simplebar/issues/476#issuecomment-642779891 */

.simplebar-scrollbar-custom:before {
    background: #ced4da !important;
}

/*
    Styles below were copied from:
    https://github.com/Grsmto/simplebar/blob/master/packages/simplebar-core/src/simplebar.css
*/

.simplebar-scrollbar-custom {
    position: absolute;
    left: 0;
    right: 0;
    min-height: 10px;
}

.simplebar-scrollbar-custom:before {
    position: absolute;
    content: '';
    background: black;
    border-radius: 7px;
    left: 2px;
    right: 2px;
    opacity: 0;
    transition: opacity 0.2s 0.5s linear;
}

.simplebar-scrollbar-custom.simplebar-visible:before {
    opacity: 0.5;
    transition-delay: 0s;
    transition-duration: 0s;
}

.simplebar-scrollbar-custom:before {
    top: 2px;
    bottom: 2px;
    left: 2px;
    right: 2px;
}

/* END OF CUSTOM SIMPLEBAR CSS */

.btn {
    --vz-btn-padding-x: 1.1rem;
    --vz-btn-padding-y: 0.3rem;
}

.footer {
    left: 350px;
}

.table {
    font-size: 10px;
    width: 100%;
}

.table>tbody.no-border>tr>td {
    border: none;
}

.table>thead {
    background-color: #09564b;
    color: white;
}

.table>thead>tr>th {
    background-color: #09564b;
    border-top: 2px solid #f3f3f3;
    white-space: nowrap;
}

.table>tbody>tr>th {
    background-color: #F5F5F5;
    font-weight: bold;
    padding: 16px 12px;
    text-align: center;
    vertical-align: middle;
}

.table.sticky>thead>tr>th:first-child,
.table.sticky>tbody>tr>td:first-child,
.table.sticky>tbody>tr>th:first-child {
    box-shadow: 0px 4px 12px #5E5E5E;
    left: 0;
    position: -webkit-sticky;
    position: sticky;
    z-index: 2;
}

.table>tbody>tr>td {
    background-color: white;
}

.table>tbody>tr>td,
.table>tbody>tr>th {
    padding: 8px 12px;
}

.table>thead>tr>th,
.table>tbody>tr>td {
    text-align: center;
    vertical-align: middle;
}

/* Emission and Notes columns are excluded */
.table-iso-container > tbody > tr > td:not(:first-child):not(:last-child) {
    text-align: right;
}

/* Emission and Notes columns are excluded */
.table-iso-container > tbody > tr >th:not(:first-child):not(:last-child) {
    text-align: right;
}