.flexContainer {
  display: flex;
  flex-wrap: wrap;
  font-size: 11px;
  text-align: left;
  width: 100%;
  justify-content: center;
}

.flexItemLeft {
  padding: 10px;
  flex: 80% 1;
  align-self: center;
  display: flex;
  justify-content: center
}

.flexItemRight {
  padding: 10px;
  flex: 20%;
  align-self: center;
}

@media (max-width: 800px) {

  .flexItemLeft {
    flex: 100%;
  }

  .flexItemRight {
    flex: 100%;
  }
}