.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
  gap: 1rem;
  margin-bottom: 1rem;
}

@media screen and (max-width: 1200px) {
  .gridFour {
    grid-template-columns: repeat(2, 1fr);
  }

  .gridThree {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media screen and (max-width: 768px) {
  .grid {
    grid-template-columns: 1fr;
  }
}