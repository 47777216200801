.card {
  background-color: white;
  border-radius: 0.75rem;
  box-shadow: 0 1px 2px rgba(56, 65, 74, .15);
  transition: all .4s;
  display: flex;
}

.card:hover {
  transform: translateY(-0.3rem);
  box-shadow: 0 5px 10px rgba(30, 32, 37, 0.12);
}

.cardBody {
  padding: 1rem;
  min-height: 10.625rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}